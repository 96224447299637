import { defineStore } from "pinia";
import useSupabase from "@/composables/useSupabase";
const { supabase } = useSupabase();

import useAuthUser from "@/composables/useAuthUser";
const { user } = useAuthUser();

export const useJournalStore = defineStore("journal", {
  state: () => {
    return {
      journalFromIndex: 0,
      journalToIndex: 49,
      journalList: [],
      journalCount: 0,
    };
  },

  actions: {
    async getJournalsFromSupabase(fromIndex, toIndex) {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      let dataSupabase = await supabase
        .from("journal_data")
        .select()
        .eq("user_id", user.value.id)
        .range(fromIndex, toIndex)
        .order("journal_date", { ascending: false });

      // for (let i = 0; i < dataSupabase.data.length; i++) {
      //   this.journalList.push(dataSupabase.data[i]);
      // }

      this.journalList.push(...dataSupabase.data);

      this.getJournalCount();

      if (dataSupabase.error) {
        console.log("error", dataSupabase.error);
      }
    },

    async getJournalCount() {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      const { count, error } = await supabase
        .from("journal_data")
        .select("*", { count: "exact", head: true })
        .eq("user_id", user.value.id);
      this.journalCount = count;
      if (error) {
        console.log("error", error);
      }
      // console.log(this.journalCount);
      // console.log(this.journalList);
      return count;
    },

    async updateJournalList() {
      this.journalList = [];
    },

    async getLatestJournal() {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      let dataSupabase = await supabase
        .from("journal_data")
        .select()
        .eq("user_id", user.value.id)
        .order("journal_date", { ascending: false })
        .limit(1);

      this.journalList.push(...dataSupabase.data);

      if (dataSupabase.error) {
        console.log("error", dataSupabase.error);
      }
    },

    async updateJournal(journalData) {
      let dataSupabase = await supabase
        .from("journal_data")
        .upsert(journalData)
        .select();

      if (dataSupabase.error) {
        // console.log("error", dataSupabase.error);
      }
    },

    async deleteJournal(id) {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      let dataSupabase = await supabase
        .from("journal_data")
        .delete()
        .eq("id", id)
        .eq("user_id", user.value.id);

      if (dataSupabase.error) {
        console.log("error", dataSupabase.error);
      }
    },
  },
});
