<template>
  <ion-app class="backdrop-no-scroll">
    <ion-router-outlet v-if="dataList.length || loadPage" />
    <div class="splash-screen" v-else>
      <ion-spinner name="lines" class="loading-spinner"></ion-spinner>
      <img src="./assets/images/logo/logo.png" class="loading-logo" />
      <ion-text class="heading">Zenify</ion-text>
    </div>

    <ion-toast
      v-if="!online"
      mode="md"
      message="Device Is Currently Offline."
      :icon="cloudOfflineOutline"
    ></ion-toast>
    <!-- <ion-toast
      v-if="error"
      mode="md"
      :message="errorMsg"
      :icon="alertCircleOutline"
    ></ion-toast> -->
  </ion-app>
</template>

<script setup>
import {
  IonApp,
  IonRouterOutlet,
  IonSpinner,
  IonToast,
  IonText,
  isPlatform,
} from "@ionic/vue";
import { ref } from "vue";
import { cloudOfflineOutline } from "ionicons/icons";
import { useDataStore } from "@/stores/data";
import { useVideoStore } from "@/stores/videos";
import { useCheckinStore } from "@/stores/checkin";
import { useJournalStore } from "@/stores/journal";
import { useChallengeStore } from "@/stores/challenge";
import { StatusBar, Style } from "@capacitor/status-bar";

import useSupabase from "@/composables/useSupabase";
import useAuthUser from "@/composables/useAuthUser";
import { useRevenueStore } from "@/stores/revenue";
import { useRouter, useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useOnline } from "@vueuse/core";

const online = useOnline();
const { supabase } = useSupabase();
const dataStore = useDataStore();
const videoStore = useVideoStore();
const checkinStore = useCheckinStore();
const journalStore = useJournalStore();
const challengeStore = useChallengeStore();
const revenueStore = useRevenueStore();
const { user } = useAuthUser();
const router = useRouter();
const route = useRoute();
let errorMsg = ref("");
let error = ref(false);
let { dataList } = storeToRefs(dataStore);

const loadPage = ref(false);

let currMonth = ref(new Date().toLocaleString("default", { month: "short" }));
let currYear = ref(new Date().toLocaleString("default", { year: "numeric" }));

const getUser = async () => {
  const { data } = await supabase.auth.getSession();
  return data;
};

// const hideStatusBar = async () => {
//   await StatusBar.hide();
// };

const setStatusBarStyleDark = async () => {
  await StatusBar.setStyle({ style: Style.Dark });
};

if (isPlatform("capacitor")) setStatusBarStyleDark();

// if (isPlatform("android")) {
//   hideStatusBar();
// }

// refresh token every 3 days as supabase auth token is valid for max 1 week
setInterval(async () => {
  if (user.value) {
    getUser().then((data) => {
      try {
        user.value = data?.session?.user || null;
      } catch (error) {
        console.log(error);
      }
    });
  }
}, 259200);

supabase.auth.onAuthStateChange(async (event, session) => {
  // the "event" is a string indicating what trigger the state change (ie. SIGN_IN, SIGN_OUT, etc)
  // the session contains info about the current session most importanly the user dat
  // "SIGNED_IN" is triggered everytime app is switched to view too

  user.value = session?.user || null;

  if (
    (event == "SIGNED_IN" &&
      user.value &&
      !route.fullPath.includes("/email-confirmation") &&
      !route.fullPath.includes("/update-password") &&
      !route.fullPath.includes("/privacy-policy") &&
      !route.fullPath.includes("/end-user-license-agreement") &&
      route.fullPath.includes("/login")) ||
    route.fullPath.includes("/register")
  ) {
    getSupabaseData();
  }
  if (event == "SIGNED_OUT") {
    router.push({
      path: "/login",
    });
  }
});

getUser().then((data) => {
  try {
    user.value = data?.session?.user || null;
    if (
      user.value &&
      !dataList.value.length &&
      !route.fullPath.includes("/email-confirmation") &&
      !route.fullPath.includes("/update-password") &&
      !route.fullPath.includes("/privacy-policy") &&
      !route.fullPath.includes("/end-user-license-agreement")
    ) {
      getSupabaseData();
    }

    if (
      !user.value ||
      route.fullPath.includes("/email-confirmation") ||
      route.fullPath.includes("/update-password") ||
      route.fullPath.includes("/privacy-policy") ||
      route.fullPath.includes("/end-user-license-agreement")
    )
      loadPage.value = true;
  } catch (error) {
    console.log(error);
  }
});

async function getSupabaseData() {
  try {
    // get data from supabase
    dataStore.getDataFromSupabase();
    //  setup revenue cat
    await revenueStore.setRevenueCat();
    //  get offers from revenue cat
    await revenueStore.getOffer();
    //  get user paid status from revenue cat
    await revenueStore.getUserRevenueInfo();
    // get profiles from supabase
    dataStore.getProfilesFromSupabase();
    // get resgistered users from supabase
    dataStore.getUserProfilesFromSupabase();
    // get playlist from supabase
    dataStore.getPlaylistFromSupabase();
    // get bookmarks from supabase
    dataStore.getBookmarksFromSupabase();
    // get videos from supabase
    videoStore.getVideosFromSupabase();
    // get quotes from supabase (loaded in home page)
    // quoteStore.getQuotesFromSupabase();
    // get shine activities
    checkinStore.getShineActivities();
    // get down activities
    checkinStore.getDownActivities();
    // get top actvities from supabase
    checkinStore.getTopActivities();
    // get top feelings from supabase
    checkinStore.getTopFeelings();
    // get checkins from supabase
    checkinStore.getCheckinsFromSupabase(0, 49);
    // get journals from supabase
    journalStore.getJournalsFromSupabase(0, 49);
    // get all challenges from supabase
    challengeStore.getAllChallengesFromSupabase();
    // get user challenges from supabase
    challengeStore.getUserChallengesFromSupabase(0, 49);
    // get avg mood for current year
    checkinStore.getCheckinMoodAvg(currYear.value);
    // get mood breakdown for current month
    checkinStore.getCheckinmoodBreakdown(currYear.value, currMonth.value);
    //  listen to realtime changes in supabase
    // dataStore.supabaseDBchanges;
  } catch (e) {
    error.value = true;
    errorMsg.value = e;
    console.log(e);
  }
}

window.addEventListener("onCustomerInfoUpdated", (info) => {
  // handle any changes to customerInfo
  try {
    revenueStore.getUserRevenueInfo();
  } catch (e) {
    error.value = true;
    errorMsg.value = e;
    console.log(e);
  }
});
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Ephesis&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Brandon Regular";
}

@font-face {
  font-family: "Melloner";
  src: url("@/assets/fonts/MellonerRegular.ttf");
}

@font-face {
  font-family: "Billabong";
  src: url("@/assets/fonts/Billabong.otf");
}

@font-face {
  font-family: "Brandon Medium";
  src: url("@/assets/fonts/Brandon_med.otf");
}

@font-face {
  font-family: "Brandon Regular";
  src: url("@/assets/fonts/Brandon_reg.otf");
}

@font-face {
  font-family: "Brandon Bold";
  src: url("@/assets/fonts/Brandon_bold.otf");
}

@font-face {
  font-family: "Brandon Light";
  src: url("@/assets/fonts/Brandon_light.otf");
}

ion-app {
  max-width: 1280px;
  margin: 0 auto;
  background-color: #f4f5f8;
}

ion-toolbar {
  --background: var(--ion-color-whale);
}

@media (prefers-color-scheme: dark) {
  ion-toolbar {
    --ion-color-whale: #0a1629;
  }
}

@media (prefers-color-scheme: dark) {
  ion-app {
    background-color: black;
  }
}

@media only screen and (max-width: 1280px) {
  ion-content {
    width: calc(100% + 15px);
  }

  ion-content::part(scroll) {
    padding-right: 15px;
    overscroll-behavior: none;
  }
}

.splash-screen .heading {
  z-index: 1000;
  /* font-family: "Melloner"; */
  font-family: "Billabong";
  font-size: 36px;
  /* font-weight: 600; */
  color: var(--ion-color-dark-extra);
  color: var(--ion-color-dark-tint);
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: var(--ion-color-dark-extra);
  overflow: visible;
  text-indent: 10px;
  margin-right: 10px;
  position: absolute;
  bottom: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.splash-screen .loading-spinner {
  height: 2em;
  width: 2em;
  z-index: 100;
  color: whitesmoke;
  margin-right: 10px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.splash-screen .loading-logo {
  z-index: 10;
  color: var(--ion-color-dark);
  margin-right: 10px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 192px;
}
</style>
