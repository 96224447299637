import { defineStore } from "pinia";

import useSupabase from "@/composables/useSupabase";

const { supabase } = useSupabase();

export const useVideoStore = defineStore("videos", {
  state: () => {
    return {
      videoList: [],
    };
  },

  actions: {
    async getVideosFromSupabase() {
      let dataSupabase = await supabase
        .from("videos")
        .select()
        .order("paid", { ascending: true });
      this.videoList = dataSupabase.data;
      if (dataSupabase.error) {
        // console.log("error", dataSupabase.error);
        // alert("Error fetching data, please try again");
      }
    },
  },

  getters: {},
});
