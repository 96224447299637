import { defineStore } from "pinia";
// import { Purchases } from "@awesome-cordova-plugins/purchases";
import { Purchases } from "@revenuecat/purchases-capacitor";

import {
  ErrorCode,
  Purchases as WebPurchases,
  PurchasesError,
} from "@revenuecat/purchases-js";
import useSupabase from "@/composables/useSupabase";
import useAuthUser from "../composables/useAuthUser";
import { useRouter } from "vue-router";
import { isPlatform } from "@ionic/vue";

const router = useRouter();
const { user } = useAuthUser();
const { supabase } = useSupabase();

export const useRevenueStore = defineStore("revenue", {
  state: () => {
    return {
      showMonthlySub: false,
      offer: null,
      offer_annual: true,
      paidUser: false,
    };
  },

  actions: {
    async setRevenueCat() {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      try {
        if (isPlatform("android")) {
          await Purchases.configure({
            apiKey: "goog_HviOHLNkwZDsNaBirhKTSVmFcgU",
            appUserID: user.value.id,
          }).then(() => {
            Purchases.setAttributes({
              Name: user.value.user_metadata.fullName,
              Email: user.value.email,
            });
          });
        } else if (isPlatform("ios")) {
          await Purchases.configure({
            apiKey: "appl_ShjxODnVoMMlLTjOQWoPnrdybZp",
            appUserID: user.value.id,
          }).then(() => {
            Purchases.setAttributes({
              Name: user.value.user_metadata.fullName,
              Email: user.value.email,
            });
          });
        } else if (!isPlatform("capacitor")) {
          WebPurchases.configure(
            "rcb_fYqetBttwgvfKJGMSMCPhnaVdYqF",
            user.value.id
          );
        }

        // Purchases.setDebugLogsEnabled(true);
        // console.log("Revenue cat setup with appUserID : ", user.value.id);
      } catch (error) {
        console.log(error);
      }
    },

    async getUserRevenueInfo() {
      try {
        if (isPlatform("capacitor")) {
          await Purchases.addCustomerInfoUpdateListener((customerInfo) => {
            // access latest customerInfo
            if (
              typeof customerInfo.entitlements.active["pro"] !== "undefined"
            ) {
              // Grant user "pro" access
              this.paidUser = true;
            } else this.paidUser = false;
            (error) => {
              // Error fetching customer info
              console.log(error);
              // if (error) throw error;
            };
          });
        } else if (!isPlatform("capacitor")) {
          WebPurchases.getSharedInstance()
            .getCustomerInfo()
            .then((customerInfoWeb) => {
              // access latest customerInfo
              if ("pro" in customerInfoWeb.entitlements.active) {
                // Grant user "pro" access
                this.paidUser = true;
              } else this.paidUser = false;
              (error) => {
                // Error fetching customer info
                console.log(error);
                // if (error) throw error;
              };
            });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getOffer() {
      try {
        if (isPlatform("capacitor")) {
          const offerings = await Purchases.getOfferings();
          console.log(offerings);
          this.offer = offerings.current;
          // console.log("Revenuecat offer fetched!");
        } else if (!isPlatform("capacitor")) {
          const offerings =
            await WebPurchases.getSharedInstance().getOfferings();
          this.offer = offerings.current;
          // console.log("Revenuecat offer fetched!");
        }
      } catch (error) {
        // console.log(error);
        // console.log("Revenuecat offer can not be fetched!");
        if (isPlatform("capacitor")) if (error) throw error;
        if (error) console.log(error);
      }
    },

    async makePayment() {
      try {
        // let offerPeriod = this.offer_annual
        //   ? this.offer.annual
        //   : this.offer.monthly;

        const offerings = await Purchases.getOfferings();

        const purchaseResult = await Purchases.purchasePackage({
          aPackage: this.offer_annual
            ? offerings.current.annual
            : offerings.current.monthly,
        });

        typeof purchaseResult.customerInfo.entitlements.active["pro"] !==
          "undefined";
        {
          // Unlock that great "pro" content
          this.paidUser = true;
        }
      } catch (error) {
        if (error) throw error;
      }
    },

    async restorePurchase() {
      try {
        Purchases.restoreTransactions(
          (info) => {
            //... check customerInfo to see if entitlement is now active
            alert(info);
          },
          (error) => {
            // Error restoring purchases
            if (error) throw error;
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
});
