import { defineStore } from "pinia";
import useSupabase from "@/composables/useSupabase";
import useAuthUser from "../composables/useAuthUser";

const { user } = useAuthUser();
const { supabase } = useSupabase();

export const useDataStore = defineStore("data", {
  state: () => {
    return {
      dataList: [],
      playlistItems: [],
      bookmarkItems: [],
      playlist: [],
      bookmarks: [],
      dataListFiltered: [],
      dataListGoal: [],
      dataListSleep: [],
      dataListMeditation: [],
      dataListMusic: [],
      registeredUsersList: [],
      recentList: [],
      userProgress: {
        mindfulTime: 0,
        totalDays: 1,
        contineousDays: 0,
        previousDate: null,
      },
      userAccountDeleteStatus: false,
      reviewProvided: false,
      hideNavButton: false,
    };
  },

  actions: {
    async getDataFromSupabase() {
      let dataSupabase = await supabase
        .from("data")
        // .select()
        .select(
          "id, title, type, mediaUrl, imageUrl, duration, paid, rating, goal"
        )
        .order("title", { ascending: true });

      this.dataList = dataSupabase.data;

      if (dataSupabase.error) {
        console.log("error", dataSupabase.error);
      }
    },

    async getItemDescFromSupabase(title) {
      let dataSupabase = await supabase
        .from("data")
        .select("description")
        .eq("title", title);

      if (dataSupabase.error) {
        console.log("error", dataSupabase.error);
      }
      return dataSupabase.data[0].description;
    },

    async getPlaylistFromSupabase() {
      this.getPlaylistItemsFromSupabase().then(async () => {
        let pl = await supabase
          .from("data")
          .select(
            "id, title, type, mediaUrl, imageUrl, duration, paid, rating, goal"
          )
          .in("id", this.playlistItems);
        this.playlist = pl.data;
      });
    },

    async getBookmarksFromSupabase() {
      this.getBookmarkItemsFromSupabase().then(async () => {
        let bm = await supabase
          .from("data")
          .select(
            "id, title, type, mediaUrl, imageUrl, duration, paid, rating, goal"
          )
          .in("id", this.bookmarkItems);
        this.bookmarks = bm.data;
      });
    },

    async getPlaylistItemsFromSupabase() {
      let playlistSupabase = await supabase
        .from("profiles")
        .select("playlist_items");
      if (playlistSupabase.data[0]) {
        let pl = playlistSupabase.data[0].playlist_items;
        if (pl != null) {
          let [...items] = pl;
          this.playlistItems = items;
        }
      }
    },

    async getBookmarkItemsFromSupabase() {
      let bookmarksSupabase = await supabase
        .from("profiles")
        .select("bookmark_items");
      if (bookmarksSupabase.data[0]) {
        let bm = bookmarksSupabase.data[0].bookmark_items;
        if (bm != null) {
          let [...items] = bm;
          this.bookmarkItems = items;
        }
      }
    },

    async getProfilesFromSupabase() {
      if (user.value) {
        try {
          let playlistSupabase = await supabase
            .from("profiles")
            .select("playlist_items");

          let pl = playlistSupabase.data[0].playlist_items;
          if (pl != null) {
            let [...items] = pl;
            this.playlistItems = items;
          }

          let bookmarksSupabase = await supabase
            .from("profiles")
            .select("bookmark_items");

          let bm = bookmarksSupabase.data[0].bookmark_items;
          if (bm != null) {
            let [...items] = bm;
            this.bookmarkItems = items;
          }

          let recentSupabase = await supabase
            .from("profiles")
            .select("recently_played");

          let recent = recentSupabase.data[0].recently_played;
          if (recent != null) {
            let [...items] = recent;
            this.recentList = items;
          }

          let userProgressSupabase = await supabase
            .from("profiles")
            .select("user_progress");

          let progress = userProgressSupabase.data[0].user_progress;
          // console.log(progress);
          if (progress != null) {
            let { ...items } = progress;
            this.userProgress = items;
            // console.log(this.userProgress);
          }
        } catch (error) {
          // console.log(error);
        }
      }
    },

    async getUserProfilesFromSupabase() {
      try {
        let usersEmailSupabase = await supabase
          .from("profiles")
          .select("email_id");

        this.registeredUsersList = usersEmailSupabase.data;
      } catch (error) {
        // console.log(error);
      }
    },

    async updatePlaylistStatus(playlist) {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }

      let dataSupabase = await supabase
        .from("profiles")
        .update({ playlist_items: playlist })
        .eq("id", user.value.id);
      if (dataSupabase.error) {
        // console.log("error", dataSupabase.error);
      }
    },

    async getAccountDeleteStatus(id, email) {
      const { data, error } = await supabase
        .from("delete_account")
        .select()
        .eq("user_id", id);

      if (data[0] && data[0].user_email == email)
        this.userAccountDeleteStatus = true;
      // console.log(data);
      // console.log(this.userAccountDeleteStatus);
      if (error) {
        // console.log("error", error);
      }
    },

    async updateAccountDeleteStatus(email, name) {
      let dataSupabase = await supabase
        .from("delete_account")
        .insert({ user_email: email, user_name: name });
      if (dataSupabase.error) {
        // console.log("error", dataSupabase.error);
      }
    },

    async updateBookmarkStatus(bookmark) {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }

      let dataSupabase = await supabase
        .from("profiles")
        .update({ bookmark_items: bookmark })
        .eq("id", user.value.id);

      if (dataSupabase.error) {
        // console.log("error", dataSupabase.error);
      }
    },

    async updateRecentlyPlayedStatus(recent) {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }

      // keep only 10 items in recent
      let dataSupabase = await supabase
        .from("profiles")
        .update({ recently_played: recent.slice(0, 10) })
        .eq("id", user.value.id);

      if (dataSupabase.error) {
        // console.log("error", dataSupabase.error);
      }
    },

    async trackAndUpdateProgress(time) {
      let currentDate = new Date().toLocaleDateString("en-US");
      // console.log(this.userProgress.previousDate);
      // console.log(currentDate);
      if (!this.userProgress.previousDate) {
        this.userProgress.previousDate = currentDate;
        this.updateUserProgress();
      }
      //check for mindful time
      if (time > 0) {
        this.userProgress.mindfulTime += time;
        this.updateUserProgress();
      }
      const diffTime = Math.abs(
        new Date(currentDate) - new Date(this.userProgress.previousDate)
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      // console.log(diffDays);
      // if (this.userProgress.previousDate < currentDate)
      if (diffDays > 0) {
        // console.log("this.userProgress.previousDate < currentDate");
        //check for total days
        this.userProgress.totalDays += 1;
        this.userProgress.previousDate = currentDate;
        //check for contineous days
        if (diffDays == 1) this.userProgress.contineousDays += 1;
        // update new data
        this.updateUserProgress();
      }
    },

    async updateUserProgress() {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }

      // console.log("after update", this.userProgress);
      if (this.userProgress.mindfulTime != 0) {
        let dataSupabase = await supabase
          .from("profiles")
          .update({ user_progress: this.userProgress })
          .eq("id", user.value.id);

        if (dataSupabase.error) {
          // console.log("error", dataSupabase.error);
        }
      }
    },
  },

  // getters: {
  //   supabaseDBchanges() {
  //     supabase
  //       .channel("table-db-changes")
  //       .on(
  //         "postgres_changes",
  //         { event: "*", schema: "public", table: "data" },
  //         (payload) => {
  //           // console.log("Change received!", payload);
  //           this.getDataFromSupabase();
  //         }
  //       )
  //       .subscribe();

  //     supabase
  //       .channel("table-db-changes'")
  //       .on(
  //         "postgres_changes",
  //         { event: "*", schema: "public", table: "profiles" },
  //         (payload) => {
  //           // console.log("Change received!", payload);
  //           this.getProfilesFromSupabase();
  //           // this.getPlaylistFromSupabase();
  //           // this.getBookmarksFromSupabase();
  //         }
  //       )
  //       .subscribe();
  //   },
  // },
});
