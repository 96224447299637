import { createRouter, createWebHistory } from "@ionic/vue-router";
import TabsPage from "../views/TabsPage.vue";
import useAuthUser from "@/composables/useAuthUser";

const routes = [
  {
    path: "/",
    redirect: "/tabs/home",
  },
  {
    name: "Register",
    path: "/register",
    component: () => import("@/views/RegisterPage.vue"),
  },
  {
    name: "EmailConfirmation",
    path: "/email-confirmation",
    component: () => import("@/views/EmailConfirmationPage.vue"),
  },
  {
    name: "Login",
    path: "/login",
    component: () => import("@/views/LoginPage.vue"),
  },
  {
    name: "ForgotPassword",
    path: "/forgot-password",
    component: () => import("@/views/ForgotPasswordPage.vue"),
  },

  {
    name: "UpdatePassword",
    path: "/update-password",
    component: () => import("@/views/UpdatePasswordPage.vue"),
  },
  {
    name: "PrivacyPolicy",
    path: "/privacy-policy",
    component: () => import("@/views/PrivacyPolicyPage.vue"),
  },
  {
    name: "EULA",
    path: "/end-user-license-agreement",
    component: () => import("@/views/EULAPage.vue"),
  },
  {
    path: "/tabs/",
    component: TabsPage,
    children: [
      {
        path: "",
        redirect: "/tabs/home",
      },
      {
        path: "home",
        component: () => import("@/views/HomePage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "library",
        component: () => import("@/views/LibraryPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "goals",
        component: () => import("@/views/GoalsPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "search-landing",
        component: () => import("@/views/SearchLandingPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "search/:type",
        component: () => import("@/views/SearchPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "audiolist-player/:title",
        component: () => import("@/views/AudioListPlayerPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "item-details/:title",
        component: () => import("@/views/ItemDetailsPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "audio-player/:title",
        component: () => import("@/views/AudioPlayerPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "breathe",
        component: () => import("@/views/BreathePage.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "breathe-player",
        component: () => import("@/views/BreathePlayerPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "item-list/:type",
        component: () => import("@/views/ItemListPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "reflections",
        component: () => import("@/views/ReflectionsPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "timer",
        component: () => import("@/views/TimerPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "timer-player",
        component: () => import("@/views/TimerPlayerPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "recently-played",
        component: () => import("@/views/RecentPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "paywall",
        component: () => import("@/views/PaywallPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "video-player/:moveType",
        component: () => import("@/views/VideoPlayerPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "video-player-search",
        component: () => import("@/views/VideoPlayerSearchPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "moves",
        component: () => import("@/views/MovesPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "quote",
        component: () => import("@/views/QuotePage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "journal",
        component: () => import("@/views/JournalPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "mood",
        component: () => import("@/views/MoodCheckinPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "settings",
        component: () => import("@/views/SettingsPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "challenge",
        component: () => import("@/views/ChallengePage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "viewJournalOrCheckin/:type/:id",
        component: () => import("@/views/ViewJournalOrCheckin.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "viewChallenge/:id",
        component: () => import("@/views/ViewChallengePage.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "assesment",
        component: () => import("@/views/AssesmentPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  // here we check it the user is logged in
  // if they aren't and the route requries auth we redirect to the login page
  const { isLoggedIn } = useAuthUser();

  let userLoggedIn = await isLoggedIn();

  if (!userLoggedIn && to.meta.requiresAuth) {
    return { name: "Login" };
  }
});

export default router;
