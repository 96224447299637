import { ref } from "vue";
import useSupabase from "@/composables/useSupabase";
import { useRouter, useRoute } from "vue-router";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
// import {
//   SignInWithApple,
//   SignInWithAppleResponse,
//   SignInWithAppleOptions,
// } from "@capacitor-community/apple-sign-in";
import { Plugins } from "@capacitor/core";
const { SignInWithApple } = Plugins;
// user is set outside of the useAuthUser function
// so that it will act as global state and always refer to a single user
const user = ref(null);
const router = useRouter();

export default function useAuthUser() {
  const { supabase } = useSupabase();

  //...

  /**
   * Login with email and password
   */
  const login = async ({ email, password }) => {
    const { user, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) throw error;
    return user;
  };

  /**
   * Login with google, github, etc
   */
  const loginWithSocialProvider = async (provider) => {
    const { user, session, error } = await supabase.auth.signInWithOAuth({
      provider: provider,
    });
    if (error) throw error;
    return user;
  };

  const googleSignIn = async () => {
    try {
      const userInfo = await GoogleAuth.signIn();
      if (userInfo.authentication.idToken) {
        const { data, error } = await supabase.auth.signInWithIdToken({
          provider: "google",
          token: userInfo.authentication.idToken,
        });
        // console.log(data);
        if (error) throw error;
        if (data.user.role == "authenticated") {
          let user = data.user;
          return user;
        }
      } else {
        throw new Error("no ID token present!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  function generateNonce(length) {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    return btoa(String.fromCharCode.apply(null, array));
  }

  const appleSignIn = async () => {
    try {
      const userInfo = await SignInWithApple.Authorize();
      console.log(userInfo);
      if (userInfo.response.identityToken) {
        const { data, error } = await supabase.auth.signInWithIdToken({
          provider: "apple",
          token: userInfo.response.identityToken,
        });
        console.log(data);
        if (error) throw error;
        if (data.user.role == "authenticated") {
          let user = data.user;
          return user;
        }
      } else {
        throw new Error("no ID token present!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Logout
   */
  const logout = async () => {
    try {
      if (user.value) {
        await supabase.auth.signOut();
        await GoogleAuth.signOut();
      }
    } catch (error) {
      if (error) throw error;
    }
  };

  /**
   * Check if the user is logged in or not
   */
  const isLoggedIn = async () => {
    const { data } = await supabase.auth.getSession();
    return data?.session?.user;
    // return user.value;
  };

  /**
   * Register
   */
  const register = async (email, password, fullName) => {
    const { data, error } = await supabase.auth.signUp({
      email: email,
      password: password,
      options: {
        data: {
          fullName: fullName,
        },
        // redirectTo: `${window.location.origin}/home?fromEmail=registrationConfirmation"`,
        emailRedirectTo: "https://app.meditationblue.com/email-confirmation",
      },
    });

    // const { user, error } = await supabase.auth.signUp(
    //   { email, password },
    //   {
    //     //arbitrary meta data is passed as the second argument under a data key
    //     // to the Supabase signUp method
    //     data: meta,
    //     // the to redirect to after the user confirms their email
    //     // window.location wouldn't be available if we were rendering server side
    //     // but since we're all on the client it will work fine
    //     redirectTo: `${window.location.origin}/home?fromEmail=registrationConfirmation"`,
    //   }
    // );
    if (error) throw error;

    return user;
  };

  /**
   * Update user email, password, or meta data
   */
  const update = async (data) => {
    const { user, error } = await supabase.auth.update(data);
    if (error) throw error;
    return user;
  };

  /**
   * Send user an email to reset their password
   * (ie. support "Forgot Password?")
   */
  const sendPasswordRestEmail = async (email) => {
    const { user, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: "https://app.meditationblue.com/update-password",
    });
    if (error) throw error;
    return user;
  };

  const updatePassword = async (new_password) => {
    const { user, error } = await supabase.auth.updateUser({
      password: new_password,
    });
    if (error) throw error;
    return user;
  };

  return {
    user,
    login,
    loginWithSocialProvider,
    isLoggedIn,
    logout,
    register,
    update,
    sendPasswordRestEmail,
    updatePassword,
    googleSignIn,
    appleSignIn,
  };
}
