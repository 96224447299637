import { defineStore } from "pinia";
import useSupabase from "@/composables/useSupabase";
const { supabase } = useSupabase();

import useAuthUser from "@/composables/useAuthUser";
const { user } = useAuthUser();

export const useChallengeStore = defineStore("challenge", {
  state: () => {
    return {
      challengeFromIndex: 0,
      challengeToIndex: 49,
      allChallengeList: [],
      todaysChallenge: "",
      userChallengeList: [],
      challengeCount: 0,
      challengeAccepted: false,
      acceptedChallenge: "",
      acceptedChallengeDateString: "",
    };
  },

  actions: {
    async getAllChallengesFromSupabase() {
      let dataSupabase = await supabase.from("challenges").select();

      // for (let i = 0; i < dataSupabase.data.length; i++) {
      //   this.allChallengeList.push(dataSupabase.data[i]);
      // }
      this.allChallengeList.push(...dataSupabase.data);

      const randomIndex = Math.floor(
        Math.random() * this.allChallengeList.length
      );
      this.todaysChallenge = this.allChallengeList[randomIndex].challenge;

      this.checkAcceptedChallenge();
      this.getAcceptedChallenge();

      if (dataSupabase.error) {
        console.log("error", dataSupabase.error);
      }
    },

    async getUserChallengesFromSupabase(fromIndex, toIndex) {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      let dataSupabase = await supabase
        .from("challenge_data")
        .select()
        .eq("user_id", user.value.id)
        .range(fromIndex, toIndex)
        .order("challenge_date", { ascending: false });

      // for (let i = 0; i < dataSupabase.data.length; i++) {
      //   this.userChallengeList.push(dataSupabase.data[i]);
      // }
      this.userChallengeList.push(...dataSupabase.data);

      this.getChallengeCount();

      if (dataSupabase.error) {
        console.log("error", dataSupabase.error);
      }
    },

    async getAcceptedChallenge() {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      let dataSupabase = await supabase
        .from("challenge_data")
        .select("challenge")
        .eq("user_id", user.value.id)
        .order("challenge_date", { ascending: false })
        .limit(1);

      this.acceptedChallenge = dataSupabase.data;

      if (dataSupabase.error) {
        console.log("error", dataSupabase.error);
      }
    },

    async getLatestChallenge() {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      let dataSupabase = await supabase
        .from("challenge_data")
        .select()
        .eq("user_id", user.value.id)
        .order("challenge_date", { ascending: false })
        .limit(1);

      this.userChallengeList.push(...dataSupabase.data);

      if (dataSupabase.error) {
        console.log("error", dataSupabase.error);
      }
    },

    async checkAcceptedChallenge() {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      let dataSupabase = await supabase
        .from("challenge_data")
        .select("challenge_date")
        .eq("user_id", user.value.id)
        .order("challenge_date", { ascending: false })
        .limit(1);

      let serverDateString = dataSupabase.data[0]?.challenge_date;

      if (serverDateString) {
        if (dataSupabase.error) {
          console.log("error", dataSupabase.error);
        }

        // Parse the server's date string
        const serverDate = new Date(serverDateString);

        // Extracting parts of the date string using regular expressions
        const [, serverYear, serverMonth, serverDay] = serverDateString.match(
          /^(\d{4})-(\d{2})-(\d{2})/
        );

        // Get the current date
        const currentDate = new Date();

        // Extract date components from the current date
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const currentDay = currentDate.getDate();

        // Check if the server date is equal to the current date
        if (
          serverYear == currentYear &&
          serverMonth == currentMonth &&
          serverDay == currentDay
        ) {
          this.challengeAccepted = true;
          this.getAcceptedChallenge();
          this.acceptedChallengeDateString = serverDateString;
        } else {
          this.challengeAccepted = false;
          this.acceptedChallengeDateString = "";
        }
      } else this.challengeAccepted = false;
    },

    async compareAcceptedChallengeDate() {
      if (this.acceptedChallengeDateString) {
        // Get the current date
        const currentDate = new Date();

        // Extract date components from the current date
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const currentDay = currentDate.getDate();

        // Extracting parts of the date string using regular expressions
        const [, accYear, accMonth, accDay] =
          this.acceptedChallengeDateString.match(/^(\d{4})-(\d{2})-(\d{2})/);

        // Check if the server date is equal to the current date
        if (
          accYear != currentYear ||
          accMonth != currentMonth ||
          accDay != currentDay
        ) {
          this.challengeAccepted = false;
        }
      }
    },

    async getChallengeCount() {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      const { count, error } = await supabase
        .from("challenge_data")
        .select("*", { count: "exact", head: true })
        .eq("user_id", user.value.id);
      this.challengeCount = count;

      if (error) {
        console.log("error", error);
      }

      return count;
    },

    async updateUserChallenge(challengeData) {
      let dataSupabase = await supabase
        .from("challenge_data")
        .upsert(challengeData)
        .select();

      if (dataSupabase.error) {
        console.log("error", dataSupabase.error);
      }
    },

    async deleteUserChallenge(id) {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      let dataSupabase = await supabase
        .from("challenge_data")
        .delete()
        .eq("id", id)
        .eq("user_id", user.value.id);

      if (dataSupabase.error) {
        console.log("error", dataSupabase.error);
      }
    },
  },
});
