<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>

      <ion-tab-bar
        slot="bottom"
        v-if="isLoggedIn() && isPlatform('capacitor')"
        mode="ios"
      >
        <ion-tab-button
          tab="home"
          href="/tabs/home"
          @click="hapticsImpactLight"
        >
          <ion-icon :icon="home" />
          <!-- <ion-label
            style="font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif"
            >home</ion-label
          > -->
        </ion-tab-button>

        <ion-tab-button
          tab="library"
          href="/tabs/library"
          @click="hapticsImpactLight"
        >
          <ion-icon :icon="musicalNotes" />
          <!-- <ion-label
            style="font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif"
            >library</ion-label
          > -->
        </ion-tab-button>

        <ion-tab-button tab="journal" href="/tabs/journal"> </ion-tab-button>

        <ion-tab-button
          tab="goals"
          href="/tabs/goals"
          @click="hapticsImpactLight"
        >
          <ion-icon :icon="flag" />
          <!-- <ion-label
            style="font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif"
            >goals</ion-label
          > -->
        </ion-tab-button>

        <ion-tab-button
          tab="search"
          href="/tabs/reflections"
          @click="hapticsImpactLight"
        >
          <ion-icon :icon="pieChart" />

          <!-- <ion-label
            style="
              font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
              /* margin-top: 3px; */
            "
            >reflections</ion-label
          > -->
        </ion-tab-button>
      </ion-tab-bar>

      <ion-fab
        v-if="!hideNavButton"
        slot="fixed"
        horizontal="center"
        vertical="bottom"
        edge="false"
        :class="
          iosPlatform
            ? iPhonePlatform
              ? 'ion-fab-iphone'
              : 'ion-fab-ipad'
            : 'ion-fab'
        "
      >
        <ion-fab-button
          v-if="isPlatform('capacitor')"
          color="secondary"
          @click="hapticsImpactLight"
        >
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="top">
          <ion-fab-button
            color="medium"
            @click="
              () => {
                hapticsImpactLight();
                router.push('/tabs/journal');
              }
            "
          >
            <ion-icon v-if="iosPlatform" :icon="mic"></ion-icon>
            <ion-icon v-else :icon="journalOutline"></ion-icon>
          </ion-fab-button>
          <ion-fab-button
            color="medium"
            @click="
              () => {
                hapticsImpactLight();
                router.push('/tabs/mood');
              }
            "
          >
            <ion-icon :icon="happyOutline"></ion-icon>
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>
    </ion-tabs>
  </ion-page>
</template>

<script setup>
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  IonFab,
  IonFabButton,
  IonFabList,
} from "@ionic/vue";
import {
  home,
  flag,
  journalOutline,
  add,
  mic,
  happyOutline,
  pieChart,
  musicalNotes,
} from "ionicons/icons";

import { Haptics, ImpactStyle } from "@capacitor/haptics";
import useAuthUser from "@/composables/useAuthUser";
import { useRouter } from "vue-router";
import { isPlatform } from "@ionic/vue";
import { storeToRefs } from "pinia";
import { useRevenueStore } from "@/stores/revenue";
import { useDataStore } from "@/stores/data";
const store = useDataStore();
const { hideNavButton } = storeToRefs(store);

const revenueStore = useRevenueStore();
revenueStore.getUserRevenueInfo();
const { isLoggedIn } = useAuthUser();
const router = useRouter();

const iosPlatform = isPlatform("ios") && isPlatform("capacitor");
const iPhonePlatform = isPlatform("iphone") && isPlatform("capacitor");

const hapticsImpactLight = async () => {
  if (isPlatform("capacitor"))
    await Haptics.impact({ style: ImpactStyle.Light });
};
</script>

<style scoped>
ion-tab-button.tab-selected ion-label {
  color: #5dade2 !important;
}

ion-tab-button {
  --color-selected: #5dade2 !important;
}

ion-fab-button {
  --border-radius: 20px;
}

.ion-fab-iphone {
  margin-bottom: 28px;
}

.ion-fab-ipad {
  margin-bottom: -5px;
}
.ion-fab {
  margin-bottom: -5px;
}
</style>
