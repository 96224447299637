import { defineStore } from "pinia";
import useSupabase from "@/composables/useSupabase";
const { supabase } = useSupabase();
import useAuthUser from "@/composables/useAuthUser";
const { user } = useAuthUser();

export const useCheckinStore = defineStore("checkin", {
  state: () => {
    return {
      checkinFromIndex: 0,
      checkinToIndex: 49,
      checkinList: [],
      checkinCount: 0,
      checkinAvgMood: {
        Jan: 0,
        Feb: 0,
        Mar: 0,
        Apr: 0,
        May: 0,
        Jun: 0,
        Jul: 0,
        Aug: 0,
        Sep: 0,
        Oct: 0,
        Nov: 0,
        Dec: 0,
      },
      checkinMoodBreakdown: {
        moodTerribleCount: 0,
        moodBadCount: 0,
        moodOkayCount: 0,
        moodGoodCount: 0,
        moodAwesomeCount: 0,
      },
      checkinPreviousYear1: false,
      checkinPreviousYear2: false,
      currMonthSt: null,
      currYearSt: null,
      shineActivities: [],
      downActivities: [],
      checkinActivities: [],
      checkinFeelings: [],
    };
  },

  actions: {
    async getCheckinsFromSupabase(fromIndex, toIndex) {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      let dataSupabase = await supabase
        .from("checkin_data")
        .select()
        .eq("user_id", user.value.id)
        .range(fromIndex, toIndex)
        .order("checkin_date", { ascending: false });

      // this.checkinList = dataSupabase.data;
      this.checkinList.push(...dataSupabase.data);
      // console.log(this.checkinList);

      this.getCheckinCount();

      if (dataSupabase.error) {
        console.log("error", dataSupabase.error);
      }
    },

    async getLatestCheckin() {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      let dataSupabase = await supabase
        .from("checkin_data")
        .select()
        .eq("user_id", user.value.id)
        .order("checkin_date", { ascending: false })
        .limit(1);

      this.checkinList.push(...dataSupabase.data);

      if (dataSupabase.error) {
        console.log("error", dataSupabase.error);
      }
    },

    async getCheckinMoodCountMonth(year, month) {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      const { count, error } = await supabase
        .from("checkin_data")
        .select("*", { count: "exact", head: true })
        .eq("user_id", user.value.id)
        .eq("checkin_year", year)
        .eq("checkin_month", month);

      if (error) {
        console.log("error", error);
      }
      // console.log("getCheckinMoodCount", count);
      return count;
    },

    async getCheckinMoodCount(year, month, doing) {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      const { count, error } = await supabase
        .from("checkin_data")
        .select("*", { count: "exact", head: true })
        .eq("user_id", user.value.id)
        .eq("checkin_year", year)
        .eq("checkin_month", month)
        .eq("doing", doing);

      if (error) {
        console.log("error", error);
      }
      // console.log("getCheckinMoodCount", count);
      return count;
    },

    // moved to postgres func due to complex logic and to decrease latency
    async calculateMoodAvg(year, month) {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      let input_user_id = user.value.id;
      // console.log(input_user_id);
      let { data, error } = await supabase.rpc("calculate_mood_avg", {
        year,
        month,
        input_user_id,
      });
      if (error) console.error(error);
      else return data;
    },

    // async calculateMoodAvg(year, month) {
    //   try {
    //     const moodTypes = ["terrible", "bad", "okay", "good", "awesome"];
    //     const totalCheckinCountPromise = this.getCheckinMoodCountMonth(
    //       year,
    //       month
    //     );

    //     const countPromises = moodTypes.map((moodType) =>
    //       this.getCheckinMoodCount(year, month, moodType)
    //     );

    //     const [totalCheckinCount, ...counts] = await Promise.all([
    //       totalCheckinCountPromise,
    //       ...countPromises,
    //     ]);

    //     let totalCount = 0;
    //     counts.forEach((count, index) => {
    //       totalCount += count * (index + 1);
    //     });

    //     const avg =
    //       totalCheckinCount === 0 ? null : totalCount / totalCheckinCount;
    //     return avg;
    //   } catch (error) {
    //     console.error("Error:", error);
    //     throw error;
    //   }
    // },
    // async calculateMoodAvg(year, month) {
    //   try {
    //     const totalCheckinCount = await this.getCheckinMoodCountMonth(
    //       year,
    //       month
    //     );

    //     const terribleCount = await this.getCheckinMoodCount(
    //       year,
    //       month,
    //       "terrible"
    //     );
    //     const badCount = await this.getCheckinMoodCount(year, month, "bad");
    //     const okayCount = await this.getCheckinMoodCount(year, month, "okay");
    //     const goodCount = await this.getCheckinMoodCount(year, month, "good");
    //     const awesomeCount = await this.getCheckinMoodCount(
    //       year,
    //       month,
    //       "awesome"
    //     );

    //     const totalCount =
    //       terribleCount * 1 +
    //       badCount * 2 +
    //       okayCount * 3 +
    //       goodCount * 4 +
    //       awesomeCount * 5;

    //     // console.log("totalCount", totalCount);
    //     // console.log("totalCheckinCount", totalCheckinCount);

    //     const avg = totalCount === 0 ? null : totalCount / totalCheckinCount;
    //     // console.log(avg);
    //     return avg;

    //     // Check if totalCount is zero to avoid division by zero
    //   } catch (error) {
    //     console.error("Error:", error);
    //     // Handle the error accordingly, e.g., return a default value or re-throw the error
    //     throw error;
    //   }
    // },

    async getCheckinMoodAvg(year) {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      await Promise.all(
        months.map(async (month) => {
          const res = await this.calculateMoodAvg(year, month);
          this.checkinAvgMood[month] = res ? Math.round(res * 100) / 100 : res;
        })
      );
      this.currYearSt = year;
    },

    async getCheckinmoodBreakdown(year, month) {
      this.getCheckinMoodCount(year, month, "terrible").then((res) => {
        this.checkinMoodBreakdown.moodTerribleCount = res;
      });

      this.getCheckinMoodCount(year, month, "bad").then((res) => {
        this.checkinMoodBreakdown.moodBadCount = res;
      });

      this.getCheckinMoodCount(year, month, "okay").then((res) => {
        this.checkinMoodBreakdown.moodOkayCount = res;
      });

      this.getCheckinMoodCount(year, month, "good").then((res) => {
        this.checkinMoodBreakdown.moodGoodCount = res;
      });

      this.getCheckinMoodCount(year, month, "awesome").then((res) => {
        this.checkinMoodBreakdown.moodAwesomeCount = res;
      });
      this.currMonthSt = month;
    },

    async getCheckinCount() {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      const { count, error } = await supabase
        .from("checkin_data")
        .select("*", { count: "exact", head: true })
        .eq("user_id", user.value.id);
      this.checkinCount = count;
      if (error) {
        console.log("error", error);
      }
      // console.log(count);
      return count;
    },

    async isCheckinPreviousYearPresent1(year) {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      const { count, error } = await supabase
        .from("checkin_data")
        .select("*", { count: "exact", head: true })
        .eq("user_id", user.value.id)
        .eq("checkin_year", year);

      if (error) {
        console.log("error", error);
      }
      // console.log(count);
      if (count > 0) {
        this.checkinPreviousYear1 = true;
      }
    },

    async isCheckinPreviousYearPresent2(year) {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      const { count, error } = await supabase
        .from("checkin_data")
        .select("*", { count: "exact", head: true })
        .eq("user_id", user.value.id)
        .eq("checkin_year", year);

      if (error) {
        console.log("error", error);
      }
      // console.log(count);
      if (count > 0) {
        this.checkinPreviousYear2 = true;
      }
    },

    async getShineActivities() {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      let input_user_id = user.value.id;
      let { data, error } = await supabase.rpc("get_shine_activities", {
        input_user_id,
      });
      if (error) console.error(error);
      else this.shineActivities = data;
      // console.log(data);
    },

    async getDownActivities() {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      let input_user_id = user.value.id;
      let { data, error } = await supabase.rpc("get_down_activities", {
        input_user_id,
      });
      if (error) console.error(error);
      else this.downActivities = data;
      // console.log(data);
    },

    async getTopActivities() {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      let input_user_id = user.value.id;
      let { data, error } = await supabase.rpc("get_top_activities", {
        input_user_id,
      });
      if (error) console.error(error);
      else this.checkinActivities = data;
      // console.log(this.checkinActivities);
    },

    async getTopFeelings() {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      let input_user_id = user.value.id;
      let { data, error } = await supabase.rpc("get_top_feelings", {
        input_user_id,
      });
      if (error) console.error(error);
      else this.checkinFeelings = data;
      // console.log(this.checkinFeelings);
    },

    async updateCheckinStatus(checkinData) {
      let dataSupabase = await supabase
        .from("checkin_data")
        .upsert(checkinData)
        .select();

      if (dataSupabase.error) {
        // console.log("error", dataSupabase.error);
      }
    },

    async updatecheckinListState() {
      this.checkinList = [];
    },

    async deleteCheckinStatus(id) {
      if (!user.value) {
        const authData = await supabase.auth.getUser();
        user.value = authData.user;
      }
      let dataSupabase = await supabase
        .from("checkin_data")
        .delete()
        .eq("id", id)
        .eq("user_id", user.value.id);

      // this.checkinList = this.checkinList.filter((obj) => obj.id !== id);

      if (dataSupabase.error) {
        //   // console.log("error", dataSupabase.error);
      }
    },
  },
});
